import React from "react";
import Layout from "../components/Layout";
import Form from "../components/Form";
// import { Link } from "gatsby";
// import Logo from "../components/Logo";
import Gift from "../components/Gift";

export default () => {
    return (
        <Layout>
            <main className="flex-grow mx-auto flex flex-col justify-around">
                <Form />
                <Gift />
            </main>
        </Layout>
    );
};
